import { forwardRef, useMemo } from "react"
import QRCode from "react-qr-code"
import { HOME } from "../../../core/constants/constants"
import { ClipLoader } from "react-spinners"



const GroupPdf = forwardRef((props, ref) => {

    const {
        step,
        onCancel,
        onDownloadClick,
        makingPngs,
        groups = [],
        selectedCategories = []
    } = props

    const totalSubscribers = useMemo(() => {
        let total = 0
        for (const g of groups) total += g.subscriber
        return total
    }, [groups])


    return (
        <>
            <div
                className={`bg-white w-full p-4 flex flex-col gap-4`}
                style={{ fontFamily: 'IranSans' }}
                ref={ref}
            >
                <div className={"flex justify-between items-center"}>
                    <span className={'text-[1rem] font-semibold'}>به نام خدا</span>
                    <span className={'text-[2rem] font-semibold'}>راینتـــــــــــــــــــــــ</span>
                    <div className={'p-2 bg-red-800 text-white text-[0.8rem]'}>
                        <span className={'mb-2 text-[1rem] font-semibold'}>خیلی محرمانه</span>
                    </div>
                </div>
                <div className={'border-[1px] border-black p-4 grid grid-cols-12'}>
                    <div className={'col-span-9 flex flex-wrap gap-2 text-[0.85rem] font-semibold'}>
                        <span className={'min-w-[240px]'}>
                            <span>گزارش عملیات: </span>
                            <span>زیرساخت کناف</span>
                        </span>
                        <span className={'min-w-[240px]'}>
                            <span>زمان ایجاد گزارش: </span>
                            <span>{(new Date()).toLocaleString('fa-IR')}</span>
                        </span>
                        <span className={'min-w-[240px]'}>
                            <span>تعداد گروه ها: </span>
                            <span>{groups?.length}</span>
                        </span>
                        <span className={'min-w-[240px]'}>
                            <span>تعداد اعضاء: </span>
                            <span>{totalSubscribers}</span>
                        </span>
                        <span className={'min-w-[480px]'}>
                            <span>دسته بندی های انتخاب شده: </span>
                            <span>{selectedCategories?.map(sc => sc.name).join(' ,')}</span>
                        </span>
                    </div>
                    <div className={'col-span-3 flex items-center justify-center'}>
                        <div className={'p-2 bg-white w-[128px] h-[128px] flex flex-col gap-2 items-center justify-center'}>
                            <QRCode
                                style={{ height: "100%", width: "100%" }}
                                value={HOME}
                            />
                        </div>
                    </div>
                </div>

                <table className={'border-collapse border border-gray-900'}>
                    <thead>
                        <tr>
                            {['ردیف', 'نام گروه', 'آدرس گروه', 'نوع گروه', 'تعداد اعضاء', 'دسته بندی ها', 'سیم کارت ها'].map(h => {
                                return (
                                    <th className={'text-[0.8rem] border border-slate-600 p-1'}>{h}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {groups?.slice(step * 10, step * 10 + 10).map((item, idx) => {



                            return (
                                <tr className={'text-[0.85rem] font-semibold'}>
                                    <td className={"border border-slate-600 p-1"}>{idx + 1 + (step * 10)}</td>
                                    <td className={"border border-slate-600 p-1"}>{item.title}</td>
                                    <td className={"border border-slate-600 p-1"}>{item.address}</td>
                                    <td className={"border border-slate-600 p-1"}>{groupTypes[item.type]}</td>
                                    <td className={"border border-slate-600 p-1"}>{item.subscriber}</td>
                                    <td className={"border border-slate-600 py-2 px-1 break-words text-right"}>
                                        {item.categories.map(x => x.name).join(' ,')}
                                    </td>
                                    <td className={"border border-slate-600 py-2 px-1 break-words text-right"}>
                                        {item.sims.map(x => x.username).join(' ,')}
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <div className={'bg-blue-900 py-2 px-5 text-center text-[0.8rem] text-white'}>
                    <span>
                        تمامی اطلاعات این سند خیلی محرمانه بوده و هرگونه نشر آن موجب پیگرد و اعاده خسارت خواهد بود. سیاست های حفظ اسرار قبلا ذیل قرارداد تشریح و توافق شده است. تمامی حقوق متعلق به راینا است.
                    </span>
                </div>
            </div>
            <div className={'w-min mx-auto flex justify-center items-center gap-4'}>
                <div
                    className={'rounded-md bg-active py-2 px-5 mt-5 w-min mx-auto text-white cursor-pointer '}
                    onClick={onDownloadClick}
                >
                    {makingPngs ?
                        <ClipLoader size={24} />
                        :
                        <span>دانلود</span>
                    }
                </div>
                <div
                    className={'rounded-md border-[1px] py-2 px-5 mt-5 w-min mx-auto text-white cursor-pointer '}
                    onClick={onCancel}
                >
                    لغو
                </div>
            </div>
        </>
    )
})

const groupTypes = {
    public: 'عمومی',
    private: 'خصوصی'
}

export default GroupPdf